import React from "react";
import theme from "theme";
import { Theme, Strong, Text, Box, Icon, List, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdBuild, MdCheckCircle } from "react-icons/md";
import { FaAngellist } from "react-icons/fa";
import { IoIosCodeDownload, IoIosAddCircle } from "react-icons/io";
import { GoDatabase } from "react-icons/go";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"Services"} />
		<Helmet>
			<title>
				Services | СпецЦентр Автообслуговування та Шиномонтажу
			</title>
			<meta name={"description"} content={"Послуги автомайстерні СпецЦентр Автообслуговування та Шиномонтажу"} />
			<meta property={"og:title"} content={"Services | СпецЦентр Автообслуговування та Шиномонтажу"} />
			<meta property={"og:description"} content={"Послуги автомайстерні СпецЦентр Автообслуговування та Шиномонтажу"} />
			<link rel={"shortcut icon"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<meta name={"msapplication-TileImage"} content={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<meta name={"msapplication-TileColor"} content={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="60px 0 90px 0"
			sm-padding="60px 0 60px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			background="--color-greyD1"
			quarkly-title="Advantages/Features-6"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Text as="p" font="--base" color="--dark" margin="0px 0px 8px 0px">
					<Strong>
						Everything you need
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					lg-text-align="center"
				>
					Наші Послуги
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--light"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					lg-text-align="center"
					lg-width="100%"
				>
					Зараз ми надаємо такі послуги:{"\n\n"}
				</Text>
			</Box>
			<List
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				as="ul"
				sm-margin="24px 0 0 0"
				list-style-type="none"
				font="normal normal 18px/150% sans-serif"
				display="grid"
				flex-direction="column"
				grid-gap="24px 32px"
				lg-margin="32px 0px 0px 0px"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdBuild}
						size="20px"
						color="--dark"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" color="--light">
						Ремонт та обслуговування ходової частини{"\n\n"}
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="fa"
						icon={FaAngellist}
						size="20px"
						color="--dark"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" color="--light" font="18px/27px --fontFamily-sans">
						Діагностика і ремонт гальм та стійок{"\n\n"}
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdCheckCircle}
						size="20px"
						color="--dark"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" color="--light">
						Виправлення вирівнювання коліс та балансування{"\n\n"}
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="io"
						icon={IoIosCodeDownload}
						size="20px"
						color="--dark"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" color="--light" font="18px/27px --fontFamily-sans">
						Заміну та ремонт амортизаторів{"\n\n"}
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="io"
						icon={IoIosAddCircle}
						size="20px"
						color="--dark"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" color="--light" font="18px/27px --fontFamily-sans">
						Шиномонтаж та перевзування на зимову гуму{"\n\n"}
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="go"
						icon={GoDatabase}
						size="20px"
						color="--dark"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" color="--light">
						Базове технічне обслуговування та заміну мастильних матеріалів{"\n\n"}
					</Text>
				</Box>
			</List>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Text
					as="p"
					margin="0px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--light"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					lg-text-align="center"
					lg-width="100%"
				>
					А також ми пропонуємо зберігання другого комплекта гуми чи набору дисків з гумою власника на нашому сучасному складі для вашої зручності та збереження якості шин упродовж сезонів.{"\n\n"}
				</Text>
			</Box>
		</Section>
		<Components.Advantages />
		<Components.Contact />
		<Components.FootedDesc />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6523de57e2dd3a0022204b82"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});